import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import partnersService from "../../services/partners.service"


export const Listpartners = createAsyncThunk(
    "/post/listpartners",
    async (item,thunkAPI) => {
        try {
            const data = await partnersService.Listpartners(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const Addpartners = createAsyncThunk(
    "/post/addpartners",
    async (item,thunkAPI) => {
        try {
            const data = await partnersService.Addpartners(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const Editpartners = createAsyncThunk(
    "/post/editpartners",
    async (item,thunkAPI) => {
        try {
            const data = await partnersService.Editpartners(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const Updatepartners = createAsyncThunk(
    "/post/updateloans",
    async (item,thunkAPI) => {
        try {
            const data = await partnersService.Updatepartners(item);
            return data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
