import React, { useState ,useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbarside from './Navbarside';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Listpartners } from '../store/slices/partners';
import { Updateclients,Editclients ,CheckGSTclients } from '../store/slices/client';
import Select from 'react-select';
import Loader from '../common/Loader';

const EditCliets = () => {
    const { id } = useParams();
    const itemId = id;

console.log('itemId',itemId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDate = new Date().toISOString().split('T')[0];

  const [isLoading, setIsLoading] = useState(false);
  const [selectedParty, setSelectedParty] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);
  const [listParties, setListpartners] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  
  const UserID = user?.data?.id;
  const Name = user?.data?.company_name;
  const user_type = user?.data?.user_type;

  const [formData, setFormData] = useState({
          name: '',
          address: '',
          email: '',
          phoneno: '',
          lic_validity_days: '',
          gst: '',
          company_name: '',
          da_type: '',
          partner_id : '',
          profile_password: '',
          date_added: currentDate,
          module: ''
  });


  useEffect(() => {
    if (itemId) {
      setIsLoading(true);
      dispatch(Editclients({ id: itemId})) 
        .unwrap()
        .then((data) => {
          setIsLoading(false);
          const item = data?.data;
          console.log('item',item);
            if (item?.partner_id) {
                fetchParties().then((ndata) => {
                   handlePartyChange(item?.partner_id, ndata, true);
                });
            }
          setFormData((prevFormData) => ({
            ...prevFormData,
                id:item?.id ||'',
                name: item?.name ||'',
                address: item?.address ||'',
                email: item?.email ||'',
                phoneno: item?.phoneno ||'',
                lic_validity_days: item?.name ||'',
                gst: item?.gst ||'',
                company_name: item?.company_name ||'',
                da_type:item?.da_type ||'',
                partner_id :item?.partner_id ||'',
                date_added: item?.date_added ||'',
          }));
        })
        .catch(({ message }) => {
          setIsLoading(false);
          console.log(message);
        });
    }
  }, [dispatch, itemId]);

    // Fetch parties
    useEffect(() => {
        fetchParties();
    }, [dispatch, id]);

  const [errors, setErrors] = useState({});

  const fetchParties = async () => {
    try {
      const data = await dispatch(Listpartners()).unwrap();
      setListpartners(data?.data);
      return data?.data;
    } catch (error) {
      console.log('Error fetching parties:', error.message);
    }
  };


  const handleInputChange = (e) => {
    const { name, value,checked} = e.target;


    if (checked) {
      // If checked, add the value to the selectedModules array
      setSelectedModules((prev) => [...prev, value]);
    } else {
      // If unchecked, remove the value from the selectedModules array
      setSelectedModules((prev) => prev.filter((module) => module !== value));
    }
    if (name === 'opening_blance') {
      if (/^\d*\.?\d{0,2}$/.test(value)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

  };

//   const handlePartyChange = (selectedOption) => {
//     setSelectedParty(selectedOption);
//     const partyId = selectedOption.value;
//     setFormData((prevFormData) =>({ ...prevFormData, partner_id: partyId}));
//   };

  const handlePartyChange = (selectedOption, ndata, isData) => {
    console.log('selectedOption, ndata, isData',selectedOption, ndata, isData);
    
    const FinalselectedOption = isData ? selectedOption : selectedOption?.value; 
    if (!FinalselectedOption) {
      console.error('FinalselectedOption is undefined or invalid');
      return;
    }
    const ListParties = isData ? ndata : listParties;
    const party = ListParties?.find((p) => p?.id == FinalselectedOption);
    setSelectedParty(party.id);
    setFormData((prevFormData) =>({ ...prevFormData, partner_id: party.id}));
  };


  const partyOptions = listParties.map((party) => ({
      value: party.id,
      label: party.name,
  }));



  const validate = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required.';
    

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    const moduleString = selectedModules.join(',');

    const updatedFormData = {
      ...formData,
      module: moduleString,
    };
    console.log(formData)
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      dispatch(Updateclients(updatedFormData))
        .unwrap()
        .then((data) => {
          setIsLoading(false);
          setFormData({
            name: '',
            address: '',
            email: '',
            phoneno: '',
            lic_validity_days: '',
            gst: '',
            partner_id : '',
            company_name: '',
            da_type: '',
            profile_password: '',
            date_added: currentDate,
            module: ''
          });
          
          console.log('Form submitted successfully', data);
          navigate('/clients/list');
        })
        .catch(({ message }) => {
          setIsLoading(false);
          console.log(message);
        });
    }
  };
  console.log('selectedParty',selectedParty);

  return (
    <div>
      <div className="row" style={{ marginLeft: '0', marginRight: '0' }}>
        <Navbarside />
        {isLoading && <Loader />}
        <div className="container">
            <div className="col-md-12">
            <div className="row content-body">
                <div className="container-fluid">
                <div className="page-header">
                    <div>
                    <h2 className="main-content-title tx-24 mg-b-5">Client Edit</h2>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                        <a href="#">Client List</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                           Client Edit
                        </li>
                    </ol>
                    </div>
                    <div className="d-flex justify-content-end" >
                        <button className="btn ripple btn-default" onClick={handleSubmit}>Update </button>
                        <a class="btn btn-cancel" onClick={() => navigate('/clients/list')}>Cancel</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div class="card custom-card">
                            <div class="card-body">
                            {user_type === "Admin" && (
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-6">
                                        <label>Client Ownership <span class="required">*</span> </label>
                                        <Select options={partyOptions} name="partner_id" placeholder="--Select Customer--" onChange={handlePartyChange} value={partyOptions?.find((option) => option?.value === selectedParty) || null} />
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-6">
                                           <label>Name</label>
                                           <input name="name" type="text" className="form-control" value={formData.name} onChange={handleInputChange} />
                                           {errors.name && <span className="text-danger">{errors.name}</span>}
                                        </div>
                                        <div class="col-md-6">
                                           <label>Phone No</label>
                                           <input name="phoneno" type="number" className="form-control" value={formData.phoneno} onChange={handleInputChange} />
                                           {errors.phoneno && <span className="text-danger">{errors.phoneno}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-6">
                                        <label>Address</label>
                                           <textarea name="address" rows="2" cols="20" maxlength="150" id="address" class="form-control mt_5" value={formData.address} onChange={handleInputChange} ></textarea>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Email</label>
                                            <input name="email" type="text" class="form-control" value={formData.email} onChange={handleInputChange} />
                                            {errors.email && <span className="text-danger">{errors.email}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Company GST </label>
                                            <input name="gst" type="text" class="form-control" value={formData.gst} onChange={handleInputChange} />
                                            {errors.gst && <span className="text-danger">{errors.gst}</span>}
                                        </div>
                                        <div class="col-md-6">
                                            <label>Company Name</label>
                                            <input name="company_name" type="text" class="form-control" value={formData.company_name} onChange={handleInputChange} />
                                            {errors.company_name && <span className="text-danger">{errors.company_name}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Profile Password</label>
                                            <input name="profile_password" type="password" class="form-control" value={formData.profile_password} onChange={handleInputChange} />
                                        </div>
                                        <div class="col-md-3">
                                            <label>Validity</label>
                                            <input name="lic_validity_days" type="text" class="form-control" value={formData.lic_validity_days} onChange={handleInputChange} />
                                        </div>
                                        <div class="col-md-3">
                                            <label>Data Access Type</label>
                                            <select name="da_type" className="form-control" onChange={handleInputChange} value={formData.da_type}>
                                                <option>Select Data Access</option>
                                                <option value="0">Read/Write</option>
                                                <option value="1">Read</option>
                                                <option value="2">Write</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <table id="MainContent_CheckBoxList_Pack" class="CheckboxList mt_10">
                                          <tbody>
                                            <tr>
                                                <td>
                                                  <input id="MainContent_CheckBoxList_Pack_0" type="checkbox" onChange={handleInputChange}  name="module[]" checked={selectedModules.includes("Invoicing")} value="Invoicing"/>
                                                  <label for="MainContent_CheckBoxList_Pack_0">Invoicing</label>
                                                </td>
                                                <td>
                                                  <input id="MainContent_CheckBoxList_Pack_1" type="checkbox" checked={selectedModules.includes("FPO Dashboard")} name="module[]"  onChange={handleInputChange}  value="FPO Dashboard"/>
                                                  <label for="MainContent_CheckBoxList_Pack_1">FPO Dashboard</label>
                                                </td>
                                                <td>
                                                  <input id="MainContent_CheckBoxList_Pack_2" type="checkbox" name="module[]" checked={selectedModules.includes("Journal")}  onChange={handleInputChange} value="Journal"/>
                                                  <label for="MainContent_CheckBoxList_Pack_2">Journal</label>
                                                </td>
                                                <td>
                                                  <input id="MainContent_CheckBoxList_Pack_3" type="checkbox" name="module[]"  checked={selectedModules.includes("Account Books")} onChange={handleInputChange} value="Account Books"/>
                                                  <label for="MainContent_CheckBoxList_Pack_3">Account Books</label>
                                                </td>
                                                <td>
                                                  <input id="MainContent_CheckBoxList_Pack_4" type="checkbox" name="module[]" checked={selectedModules.includes("Reports")}  onChange={handleInputChange} value="Reports"/>
                                                  <label for="MainContent_CheckBoxList_Pack_4">Reports</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><input id="MainContent_CheckBoxList_Pack_5" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Directors")}  onChange={handleInputChange} value="Reg Directors"/><label for="MainContent_CheckBoxList_Pack_5">Reg Directors</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_6" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Farmer")}  onChange={handleInputChange} value="Reg Farmer"/><label for="MainContent_CheckBoxList_Pack_6">Reg Farmer</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_7" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Certificates")}  onChange={handleInputChange} value="Reg Certificates"/><label for="MainContent_CheckBoxList_Pack_7">Reg Certificates</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_8" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Compliance")}  onChange={handleInputChange} value="Reg Compliance"/><label for="MainContent_CheckBoxList_Pack_8">Reg Compliance</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_9" type="checkbox" name="module[]"  checked={selectedModules.includes("Reg Company")} onChange={handleInputChange} value="Reg Company"/><label for="MainContent_CheckBoxList_Pack_9">Reg Company</label></td>
                                            </tr>
                                            <tr>
                                                <td><input id="MainContent_CheckBoxList_Pack_10" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Share Application")}  onChange={handleInputChange} value="Reg Share Application"/><label for="MainContent_CheckBoxList_Pack_10">Reg Share Application</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_11" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Members")}  onChange={handleInputChange} value="Reg Members"/><label for="MainContent_CheckBoxList_Pack_11">Reg Members</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_12" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Debenture")} onChange={handleInputChange}  value="Reg Debenture"/><label for="MainContent_CheckBoxList_Pack_12">Reg Debenture</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_13" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Dividend")}  onChange={handleInputChange} value="Reg Dividend"/><label for="MainContent_CheckBoxList_Pack_13">Reg Dividend</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_14" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Loans")}  onChange={handleInputChange} value="Reg Loans"/><label for="MainContent_CheckBoxList_Pack_14">Reg Loans</label></td>
                                            </tr>
                                            <tr>
                                                <td><input id="MainContent_CheckBoxList_Pack_15" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Assets")} onChange={handleInputChange}  value="Reg Assets"/><label for="MainContent_CheckBoxList_Pack_15">Reg Assets</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_16" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Deposits")}  onChange={handleInputChange} value="Reg Deposits"/><label for="MainContent_CheckBoxList_Pack_16">Reg Deposits</label></td>
                                                <td><input id="MainContent_CheckBoxList_Pack_17" type="checkbox" name="module[]" checked={selectedModules.includes("Reg Seal")}  onChange={handleInputChange} value="Reg Seal"/><label for="MainContent_CheckBoxList_Pack_17">Reg Seal</label></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EditCliets;
